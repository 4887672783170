import React from "react";
import { checkExistingName } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import { setNewCampaignData } from "../../store/slices/ownerSlice";
import { FiArrowRight } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { setCurrentStep } from "../../store/slices/campaignSlice";

type Props = {};

const CampaignName = (props: Props) => {
  const { owner, newCampaignData } = useSelector(ownerSelector);
  const dispatch = useDispatch();

  const handleNameCheck = async () => {
    try {
      if (
        !newCampaignData.campaignName ||
        newCampaignData.campaignName === "" ||
        newCampaignData.campaignName.trim() === ""
      ) {
        toast.error("Campaign name cannot be empty");
        return;
      }
      let isNameAvailable = await checkExistingName(
        newCampaignData.campaignName,
        owner?._id
      );

      if (isNameAvailable) {
        toast.success("Campaign name added successfully");
        dispatch(setCurrentStep({ idx: 1, key: "selectProspects" }));
      } else {
        toast.error("Campaign name already exists");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="p-6 flex flex-col justify-center items-start space-y-4">
      <h1 className="text-xl font-bold text-gray-800">Name your campaign</h1>

      <p className="text-xs text-gray-500">
        Choose a unique and descriptive name for your campaign.
      </p>

      <div className="flex items-center w-full gap-4 mt-2">
        <div className="relative">
          <FaEdit className="absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
          <input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleNameCheck();
              }
            }}
            value={newCampaignData.campaignName}
            onChange={(e) => {
              dispatch(
                setNewCampaignData({
                  ...newCampaignData,
                  campaignName: e.target.value,
                })
              );
            }}
            type="text"
            placeholder="Campaign Name"
            className="pl-8 p-2 border-2 border-mainColor/20 outline-none rounded-md focus:ring-2 focus:ring-mainColor transition-all duration-200 ease-in-out w-[250px]"
          />
        </div>
        <button
          onClick={() => handleNameCheck()}
          className="flex h-fit items-center bg-mainColor text-white p-2 rounded-md font-semibold hover:bg-mainColor/90 transition duration-300 ease-in-out text-sm"
        >
          Next <FiArrowRight className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default CampaignName;
