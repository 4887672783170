import React from "react";

import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import Home from "./pages/Home";
import Prospects from "./pages/Prospects";
import Teams from "./pages/Teams";
import Campaigns from "./pages/Campaigns/Campaigns";
import Settings from "./pages/Settings";
import "react-toastify/dist/ReactToastify.css";
import CreateCampaign from "./pages/Campaigns/CreateCampaign";
import Login from "./pages/Login";
import ImportProgressLoader from "./components/ImportProgressLoader";
import { useSelector } from "react-redux";
import { ownerSelector } from "./store/store";
import { useState, useEffect } from "react";

import ExpiredPlanModal from "./components/Modals/ExpiredPlanModal";

import { setIntercom } from "./utils/intercom";
import Billings from "./pages/Billings";

function App() {
  const { owner } = useSelector(ownerSelector);
  const [importSuccess, setimportSuccess] = useState(0);
  const [importScanned, setimportScanned] = useState(0);
  const [importDuplicates, setimportDuplicates] = useState(0);
  const [importFalsePositives, setimportFalsePositives] = useState(0);
  const [importPercent, setimportPercent] = useState(0);
  const [visibility, setVisibility] = useState("hidden");
  let location = useLocation();

  useEffect(() => {
    setIntercom();
  }, [location.pathname]);

  let utils = {
    setimportSuccess,
    setimportScanned,
    setimportDuplicates,
    setimportFalsePositives,
    setimportPercent,
    setVisibility,
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ImportProgressLoader
        visibility={visibility}
        success={importSuccess}
        scanned={importScanned}
        duplicates={importDuplicates}
        falsePositives={importFalsePositives}
        percent={importPercent}
      />
      <ExpiredPlanModal show={showModal} onClose={handleCloseModal} />
      <Routes>
        {/* <Route
        path="/login"
        element={owner ? <Navigate to={"/"} /> : <Login />}
      /> */}
        {/* <Route path="/register" element={<Login />} /> */}
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/prospects"
            element={
              <Prospects utils={utils} setShowExpiredAlert={setShowModal} />
            }
          />
          <Route path="/teams" element={<Teams />} />
          <Route path="/billings" element={<Billings />} />

          <Route path="/settings">
            <Route index element={<Navigate to="/settings/profile" />} />
            <Route path="profile" element={<Settings />} />
            <Route path="account-activity" element={<Settings />} />

            <Route path="billing" element={<Settings />} />
            {/* <Route path="integrations" element={<Settings />} /> */}
          </Route>
          <Route path="/campaigns">
            <Route index element={<Navigate to="/campaigns/list/running" />} />
            <Route path="list" element={<Campaigns />}>
              <Route path=":id" element={<Campaigns />} />
            </Route>
            <Route path="template" element={<Campaigns />} />
            <Route
              path="create"
              element={<CreateCampaign setShowExpiredAlert={setShowModal} />}
            />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
