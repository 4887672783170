import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import { FaLightbulb } from "react-icons/fa6";

type Props = {
  onClose: () => void;
};

const AddDelayModal = (props: Props) => {
  const [delay, setDelay] = useState("1");
  const { newCampaignData, actionsAdded } = useSelector(ownerSelector);
  const dispatch = useDispatch();

  const modalSelfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div
        ref={modalSelfRef}
        className="bg-white items-center justify-center text-xs absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4"
      >
        <div className="p-4 flex w-full flex-col justify-center items-center">
          <h1 className="text-xl font-semibold">Add delay</h1>
          <div className="flex w-full gap-4 mt-4">
            <input
              value={delay}
              onChange={(e) => {
                setDelay(e.target.value);
              }}
              type="number"
              min={1}
              max={20}
              placeholder="Delay in days"
              className="p-2 border-2 border-mainColor/20 w-[80%] outline-none rounded-md"
            />
            <button
              onClick={() => {
                dispatch(
                  setNewCampaignData({
                    ...newCampaignData,
                    actions: [
                      ...newCampaignData.actions,
                      { type: "delay", delay: delay },
                    ],
                  })
                );
                setDelay("1");

                dispatch(setActionsAdded([...actionsAdded, "Delay"]));
                props.onClose();
              }}
              className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
            >
              Add
            </button>
          </div>
          <div>
            <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-sm  mt-4 bg-mainColor/60 p-4 rounded-md">
              <FaLightbulb size={20} />
              <span>
                <p>You can add a delay from 1 Day to 20 Days</p>
                {/* <p>You can add more actions or start the campaign</p> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDelayModal;
