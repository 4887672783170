import { createSlice } from "@reduxjs/toolkit";
import { Action, Campaign, Prospect } from "../../types";
import { fetchCampaignData } from "../features/campaignsFeature";

export type CampaignState = {
  campaigns: Campaign[];
  singleCampaign: {
    listId: {
      listName: string;
    };
    ownerId: string;
    campaignStatus: string;
    campaignName: string;
    prospects: Prospect[];
    actions: Action[];
    prospectsWiseCampaignActions: any[];
  };
  campaignId: string;
  newCampaignData: {
    ownerId: string;
    campaignStatus: string;
    campaignName: string;
    prospects: Prospect[];
    actions: any[];
  };
  selectedCampaign: string;

  currentStep: {
    idx: number;
    key: string;
  };
};

const initialState: CampaignState = {
  campaigns: [],
  campaignId: "",
  singleCampaign: {
    listId: {
      listName: "",
    },
    ownerId: "",
    campaignStatus: "",
    campaignName: "",
    prospects: [],
    actions: [],
    prospectsWiseCampaignActions: [],
  },
  newCampaignData: {
    ownerId: localStorage.getItem("owner")
      ? JSON.parse(localStorage.getItem("owner")!)._id
      : "",
    campaignStatus: "ACTIVE",
    campaignName: "Invitation",
    prospects: [],
    actions: [],
  },
  selectedCampaign: "",
  currentStep: {
    idx: 0,
    key: "campaignName",
  },
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    setNewCampaignData: (state, action) => {
      state.newCampaignData = action.payload;
    },

    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    setSingleCampaign: (state, action) => {
      state.singleCampaign = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignData.fulfilled, (state, action) => {
      state.singleCampaign = action.payload.campaign;
    });
  },
});

export const {
  setCampaigns,
  setCampaignId,
  setNewCampaignData,
  setSelectedCampaign,
  setSingleCampaign,
  setCurrentStep,
} = campaignSlice.actions;

export default campaignSlice.reducer;
