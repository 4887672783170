import React from "react";
import Billings from "../../pages/Billings";

type Props = {};

const BillingSetting = (props: Props) => {
  return (
    <div className=" max-h-[calc(100vh-120px)] overflow-y-scroll noScroll">
      <Billings />
    </div>
  );
};

export default BillingSetting;
