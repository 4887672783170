import { configureStore } from "@reduxjs/toolkit";
import campaignSlice from "./slices/campaignSlice";
import dashboardSlice from "./slices/dashboardSlice";
import ownerSlice from "./slices/ownerSlice";
import prospectsSlice from "./slices/prospectsSlice";
import templateSlice from "./slices/templateSlice";
import paymentSlice from "./slices/paymentSlice";

const store = configureStore({
  reducer: {
    owner: ownerSlice,
    template: templateSlice,
    prospects: prospectsSlice,
    dashboard: dashboardSlice,
    campaign: campaignSlice,
    payment: paymentSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export const ownerSelector = (state: RootState) => state.owner;
export const templateSelector = (state: any) => state.template;
export const prospectsSelector = (state: RootState) => state.prospects;
export const dashboardSelector = (state: any) => state.dashboard;
export const campaignSelector = (state: RootState) => state.campaign;
export const paymentSelector = (state: RootState) => state.payment;

export default store;
