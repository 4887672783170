import React from "react";
import CreateModal from "../../components/Campaigns/CreateModal";
import CreateSteps from "../../components/Campaigns/CreateSteps";

type Props = { setShowExpiredAlert: any };

const CreateCampaign = (props: Props) => {
  return (
    <div className="h-full w-full">
      {/* <CreateModal
        onClose={() => {}}
        setShowExpiredAlert={props.setShowExpiredAlert}
      /> */}
      <CreateSteps setShowExpiredAlert={props.setShowExpiredAlert} />
    </div>
  );
};

export default CreateCampaign;
