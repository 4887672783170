import React from "react";
import { useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";

type Props = {};

const BillingOverview = (props: Props) => {
  const { owner } = useSelector(ownerSelector);
  const planhistory = owner?.planhistory[owner?.planhistory.length - 1];
  console.log("planhistory", planhistory);
  const chargedAt = planhistory?.chargedAt 
  ? new Date(planhistory.chargedAt.toString()) 
  : undefined;
  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC'
  };
  return (
    <div className="bg-white relative p-2 rounded-md text-xs flex flex-col gap-4">
      <div className="absolute right-2">
        {planhistory?.isActive ? (
          <p className="p-1 bg-green-400/40 text-green-800 rounded-lg text-xs ">
            Active
          </p>
        ) : (
          <p className="p-1 bg-red-400/40 text-red-800 rounded-lg text-xs ">
            Inactive
          </p>
        )}
      </div>

      <h1 className="text-lg font-semibold">Billing Overview</h1>
      <div className="flex justify-between items-center">
        <p className="text-xs">
          Plan:{" "}
          <span className="text-mainColor font-semibold">
            {planhistory?.planName}
          </span>
        </p>
        {/* <p>{owner?.currentPlan}</p> */}
      </div>
      <div className="flex justify-between items-center">
        <p>
          Plan Started:{" "}
          <span className="text-mainColor font-semibold">
            {chargedAt?.toLocaleString('en-US', options) || "N/A"}
          </span>
        </p>
        {/* <p>{owner?.nextBillingDate}</p> */}
      </div>
      <div className="flex justify-between items-center">
        <p>
          Amount:{" "}
          <span className="text-mainColor font-semibold">
            {planhistory?.currencySymbol} {planhistory?.planPrice}
          </span>
        </p>
        {/* <p>{owner?.amountDue}</p> */}
      </div>
    </div>
  );
};

export default BillingOverview;
