import React, { useState } from "react";
import { MdDelete } from "react-icons/md"; // Delete icon
import { FaRegEnvelope, FaUserCheck, FaClock, FaEye } from "react-icons/fa"; // Icons for actions
import { FiArrowRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, prospectsSelector } from "../../store/store";
import AddMessageModal from "./AddMessageModal";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import AddDelayModal from "./AddDelayModal";
import { setCurrentStep } from "../../store/slices/campaignSlice";
import AddInvitationNoteModal from "./AddInvitationNoteModal";

type Props = {};

const AddActions = (props: Props) => {
  const { actionsAdded, newCampaignData } = useSelector(ownerSelector);
  const [action, setAction] = useState(
    actionsAdded.length === 0 ? "Invitation" : "Visit"
  );
  const { connectedProspects } = useSelector(prospectsSelector);
  const [showDelay, setShowDelay] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const dispatch = useDispatch();

  const handleAddAction = () => {
    // console.log("Adding action", action);
    if (action === "Message" && actionsAdded.length === 0) {
      if (connectedProspects.length === 0) {
        toast.error("No connected prospects");
        return;
      } else {
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            prospects: connectedProspects,
          })
        );

        setShowMessageModal(true);
      }
    } else if (action === "Message") {
      setShowMessageModal(true);
      return;
    }

    if (action === "Invitation") {
      setShowNoteModal(true);
      // dispatch(
      //   setNewCampaignData({
      //     ...newCampaignData,
      //     actions: [...newCampaignData.actions, { type: "invitation" }],
      //   })
      // );
      // dispatch(setActionsAdded([...actionsAdded, action]));
      setAction("Visit");
    }

    if (action === "Delay") {
      setShowDelay(true);
      //   if (actionsAdded[actionsAdded.length - 1] === "Delay") {
      //     toast.error("You can't add delay after delay");
      //     return;
      //   }

      //   if (actionsAdded.length === 0) {
      //     toast.error("Please add other actions first");
      //     return;
      //   }
      //   dispatch(setActionsAdded([...actionsAdded, "Delay"]));
      //   setAddingDelay(true);
      setAction("Visit");
    } else if (action === "Message") {
      if (!actionsAdded.includes("Invitation")) {
        if (connectedProspects.length === 0) {
          toast.error("No connected prospects found! Add invitation first");
          return;
        } else {
          dispatch(
            setNewCampaignData({
              ...newCampaignData,
              prospects: connectedProspects,
            })
          );
        }
      } else {
        dispatch(setActionsAdded([...actionsAdded, "Message"]));
        setAction("Visit");
      }
    } else if (action === "Visit") {
      if (actionsAdded[actionsAdded.length - 1] === "Delay") {
        // if (!actionsAdded.includes("Invitation")) {
        //   toast.error("Please add invitation first");
        //   return;
        // }
        dispatch(setActionsAdded([...actionsAdded, action]));
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: [
              ...newCampaignData.actions,
              {
                type: "visitWithDelay",
                delay:
                  newCampaignData.actions[newCampaignData.actions.length - 1]
                    .delay,
              },
            ],
          })
        );
      } else {
        dispatch(setActionsAdded([...actionsAdded, action]));
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: [...newCampaignData.actions, { type: "visit" }],
          })
        );
      }
    }
  };

  // Function to remove action from the list
  const handleRemoveAction = (actionIndex: number) => {
    try {
      console.log("Removing action", actionsAdded);
      if (actionsAdded.length === 1) {
        dispatch(setActionsAdded([]));
        dispatch(setNewCampaignData({ ...newCampaignData, actions: [] }));
        return;
      }
      if (actionsAdded[actionIndex] === "Delay") {
        console.log(actionIndex);
        const deleteCount = actionsAdded.length - actionIndex;
        console.log(deleteCount);
        const newActions = actionsAdded.slice(0, actionIndex);
        console.log(newActions);
        dispatch(setActionsAdded(newActions));
        toast.warning("Removing delay removes all actions after it");

        // remove any message with delay or visit with delay
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            actions: newCampaignData.actions.filter(
              (action: any) =>
                action.type !== "messageWithDelay" &&
                action.type !== "visitWithDelay"
            ),
          })
        );
        return;
      }

      if (actionsAdded[actionIndex] === "Invitation") {
        if (connectedProspects.length === 0) {
          toast.error("No connected prospects for other actions!");
          return;
        } else {
          dispatch(
            setNewCampaignData({
              ...newCampaignData,
              prospects: connectedProspects,
            })
          );
        }
      }

      dispatch(
        setActionsAdded(actionsAdded.filter((a, idx) => idx !== actionIndex))
      );
      dispatch(
        setNewCampaignData({
          ...newCampaignData,
          actions: newCampaignData.actions.filter(
            (a) => a.type !== actionsAdded[actionIndex]
          ),
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionsFinalize = () => {
    dispatch(
      setNewCampaignData({
        ...newCampaignData,
        actions: newCampaignData.actions.filter((a) => a.type !== "delay"),
      })
    );

    dispatch(setCurrentStep({ idx: 3, key: "reviewLaunch" }));
  };

  // Action icons based on the action type
  const getActionIcon = (actionType: string) => {
    switch (actionType) {
      case "Invitation":
        return <FaUserCheck size={18} />;
      case "Visit":
        return <FaEye size={18} />;
      case "Message":
        return <FaRegEnvelope size={18} />;
      case "Delay":
        return <FaClock size={18} />;
      default:
        return null;
    }
  };

  return (
    <div className="p-4 flex w-full flex-col justify-center items-center">
      <h1 className="text-xl font-semibold">Add actions to your campaign!</h1>
      {action === "Message" && actionsAdded.length === 0 && (
        <small className=" font-semibold text-mainColor bg-white rounded-lg p-1">
          Only connected prospects will be added
        </small>
      )}
      <div className="flex items-center justify-center w-full gap-4 mt-4">
        <select
          value={action}
          onChange={(e) => setAction(e.target.value)}
          className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
        >
          {!actionsAdded.includes("Invitation") &&
            actionsAdded[0] !== "Message" && (
              <option value="Invitation">Invitation</option>
            )}
          <option value="Visit">Visit</option>
          <option value="Message">Message</option>
          <option value="Delay">Delay</option>
        </select>
        <div className="relative">
          <button
            onClick={handleAddAction}
            className="bg-mainColor  text-white p-2 px-4 rounded-md font-semibold"
            //   disabled={actionsAdded.includes(action)}
          >
            Add {action}
          </button>
        </div>
        <button
          onClick={() => handleActionsFinalize()}
          className="flex h-fit items-center bg-mainColor text-white p-2 rounded-md font-semibold hover:bg-mainColor/90 transition duration-300 ease-in-out text-sm"
        >
          Next <FiArrowRight className="ml-2" />
        </button>
      </div>

      <div className="mt-6 w-full flex flex-wrap items-center justify-center gap-4">
        {actionsAdded.map((addedAction, idx) => (
          <div
            key={idx}
            className="flex w-fit items-center justify-between p-2 border-2 border-mainColor gap-4 rounded-md bg-mainColor/10"
          >
            <div className="flex items-center gap-2">
              <span>{getActionIcon(addedAction)}</span>
              <p className="text-sm font-semibold">{addedAction}</p>
            </div>
            <button
              onClick={() => handleRemoveAction(idx)}
              className="text-red-500 hover:text-red-700"
            >
              <MdDelete size={18} />
            </button>
          </div>
        ))}
      </div>

      {showMessageModal && (
        <AddMessageModal onClose={() => setShowMessageModal(false)} />
      )}

      {showDelay && <AddDelayModal onClose={() => setShowDelay(false)} />}

      {showNoteModal && (
        <AddInvitationNoteModal onClose={() => setShowNoteModal(false)} />
      )}
    </div>
  );
};

export default AddActions;
