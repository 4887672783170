import { StepProps, Steps } from "antd";
import React, { useState } from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { campaignSelector, ownerSelector } from "../../store/store";
import { setCurrentStep } from "../../store/slices/campaignSlice";
import { toast } from "react-toastify";

type Props = {};

const CampaignMenu = (props: Props) => {
  const { currentStep } = useSelector(campaignSelector);
  const { newCampaignData } = useSelector(ownerSelector);
  const dispatch = useDispatch();

  const handleStepChange = (to: number, key: string) => {
    try {
      if (
        key === "selectProspects" ||
        key === "addActions" ||
        key === "reviewLaunch"
      ) {
        if (
          !newCampaignData.campaignName ||
          newCampaignData.campaignName === "" ||
          newCampaignData.campaignName.trim() === ""
        ) {
          toast.error("Add a campaign name first");
          return;
        }
      }

      if (key === "addActions") {
        if (newCampaignData.prospects.length === 0) {
          toast.error("Add prospects first");
          return;
        }
      }

      if (key === "reviewLaunch") {
        if (newCampaignData.prospects.length === 0) {
          toast.error("Add prospects first");
          return;
        }
        if (newCampaignData.actions.length === 0) {
          toast.error("Add actions first");
          return;
        }
      }

      dispatch(
        setCurrentStep({
          idx: to,
          key: "campaignName",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const steps: StepProps[] = [
    {
      title: (
        <p
          onClick={() => handleStepChange(0, "campaignName")}
          className="hover:cursor-pointer"
        >
          Campaign Name
        </p>
      ),
      description: (
        <p
          onClick={() => handleStepChange(0, "campaignName")}
          className="text-xs hover:cursor-pointer"
        >
          Give your campaign a name
        </p>
      ),
    },
    {
      title: (
        <p
          onClick={() => handleStepChange(1, "selectProspects")}
          className="hover:cursor-pointer"
        >
          Select Prospects
        </p>
      ),
      description: (
        <p
          onClick={() => handleStepChange(1, "selectProspects")}
          className="text-xs hover:cursor-pointer"
        >
          Select prospects for your campaign
        </p>
      ),
    },
    {
      title: (
        <p
          onClick={() => handleStepChange(2, "addActions")}
          className="hover:cursor-pointer"
        >
          Add Actions
        </p>
      ),
      description: (
        <p
          onClick={() => handleStepChange(2, "addActions")}
          className="text-xs hover:cursor-pointer"
        >
          Add actions for your campaign
        </p>
      ),
    },
    {
      title: (
        <p
          onClick={() => handleStepChange(3, "reviewLaunch")}
          className="hover:cursor-pointer"
        >
          Review & Launch
        </p>
      ),
      description: (
        <p
          onClick={() => handleStepChange(3, "reviewLaunch")}
          className="text-xs hover:cursor-pointer"
        >
          Review and launch your campaign
        </p>
      ),
    },
  ];

  const items = steps.map((item) => ({
    title: item.title,
    description: item.description,
  }));

  return (
    <div
      id="menu"
      className="w-[25%] h-full justify-between flex flex-col items-center"
    >
      <Steps
        size="small"
        direction="vertical"
        current={currentStep.idx}
        items={items}
      />
      {/* <div className="flex gap-4">
        {currentStep > 0 && (
          <button
            onClick={() => {}}
            className="bg-mainColor text-white p-2 px-2 rounded-md "
          >
            <FaArrowLeftLong size={20} />
          </button>
        )}
        {currentStep < steps.length - 1 && (
          <button
            onClick={() => {}}
            className="bg-mainColor text-white p-2 px-2 rounded-md "
          >
            <FaArrowRightLong size={20} />
          </button>
        )}
      </div> */}
    </div>
  );
};

export default CampaignMenu;
