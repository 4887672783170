import { MdPeople } from "react-icons/md";
import { useState } from "react";
import { toast } from "react-toastify";
import { ownerSelector, prospectsSelector } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchProspects } from "../../utils";
import { setCurrentStep } from "../../store/slices/campaignSlice";
import { setConnectedProspects } from "../../store/slices/prospectsSlice";
import { setNewCampaignData } from "../../store/slices/ownerSlice";

const AddProspects = () => {
  const { owner, lists, newCampaignData } = useSelector(ownerSelector);
  const [listId, setListId] = useState("");
  const avlLists = lists.filter(
    (list: any) =>
      !list.isDeleted &&
      list.listName !== "All Prospects" &&
      !list.inActiveCampaign &&
      list.prospects.length > 0
  );
  //   const { connectedProspects } = useSelector(prospectsSelector);
  const dispatch = useDispatch();

  const handleProspectFetch = async () => {
    if (!listId || listId === "") {
      return;
    }
    const loader = toast.loading("Fetching prospects...");
    try {
      dispatch(setNewCampaignData({ ...newCampaignData, ownerId: owner?._id }));
      const list = await fetchProspects(owner?._id as string, listId, 1, 1000);
      //   console.log(list);

      if (list.inActiveCampaign) {
        toast.dismiss(loader);
        toast.error("Prospects are already in an active campaign!");
        return;
      }
      if (list.prospects.length > 0) {
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            listId: listId,
            prospects: list.prospects.length > 0 ? list.prospects : [],
          })
        );
        const connected = list.prospects.filter(
          (prospect: any) => prospect.connected === "ACCEPTED"
        );
        // console.log(connected);
        dispatch(setConnectedProspects(connected));
        toast.dismiss(loader);
        toast.success("Prospects added successfully");
        dispatch(setCurrentStep({ idx: 2, key: "addActions" }));
      } else {
        toast.dismiss(loader);
        toast.error("No prospects in this list");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Failed to fetch prospects");
    }
  };

  //   const handleAddButtonClick = () => {
  //     if (!selectedList) {
  //       toast.error("Select a list to add prospects");
  //       return;
  //     }
  //     if (!newCampaignData.prospects || newCampaignData.prospects.length === 0) {
  //       toast.error("No prospects in this list");
  //       return;
  //     }
  //   };

  return (
    <div className="p-4 flex w-full flex-col justify-center items-center">
      {/* Header */}
      <div className="flex justify-center items-baseline gap-2">
        <MdPeople className="" size={20} />
        <h1 className="text-lg font-semibold">
          Add prospects to your campaign.
        </h1>
        <small>
          <span className="text-black font-semibold">{avlLists.length}</span>{" "}
          lists available
        </small>
      </div>

      {/* Select and Button */}
      <div className="flex items-center justify-center w-full gap-4 mt-4">
        <select
          onChange={(e) => {
            // console.log(e.target.value);
            setListId(e.target.value);
            dispatch(
              setNewCampaignData({ ...newCampaignData, listId: e.target.value })
            );
          }}
          value={newCampaignData.listId}
          className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
          name="list"
          id="list"
        >
          <option value="" disabled>
            Select a list
          </option>
          {lists.map((list: any) =>
            !list.inActiveCampaign &&
            list.prospects.length > 0 &&
            !list.isDeleted &&
            list.listName !== "All Prospects" ? (
              <option key={list._id} value={list._id}>
                {list.listName}
              </option>
            ) : null
          )}
        </select>

        <button
          //   disabled={!selectedList || fetchingProspects}
          onClick={handleProspectFetch}
          className="bg-mainColor text-white p-2 px-4 rounded-md"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddProspects;
