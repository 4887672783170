import { useSelector } from "react-redux";
import { campaignSelector } from "../../store/store";
import CampaignMenu from "./CampaignMenu";
import CampaignName from "./CampaignName";
import AddProspects from "./AddProspects";
import AddActions from "./AddActions";
import { useEffect, useState } from "react";
import ReviewCampaign from "./ReviewCampaign";

type Props = {
  setShowExpiredAlert: any;
};

const CreateSteps = (props: Props) => {
  const { currentStep } = useSelector(campaignSelector);

  return (
    <section className=" bg-white w-full rounded-md h-[calc(100vh-80px)] flex p-4 text-sm">
      <CampaignMenu />
      <div className=" bg-slate-100 rounded-md w-full h-full flex items-center justify-center">
        {currentStep.idx === 0 && <CampaignName />}
        {currentStep.idx === 1 && <AddProspects />}
        {currentStep.idx === 2 && <AddActions />}
        {currentStep.idx === 3 && (
          <ReviewCampaign setShowExpiredAlert={props.setShowExpiredAlert} />
        )}
      </div>
    </section>
  );
};

export default CreateSteps;
