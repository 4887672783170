import { createSlice } from "@reduxjs/toolkit";
import { Subscription } from "../../types";

interface PaymentState {
  subscription: Subscription | null;
  loading: boolean;
  error: string | null;
}

const initialState: PaymentState = {
  subscription: null,
  loading: false,
  error: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setSubscription, setLoading, setError } = paymentSlice.actions;

export default paymentSlice.reducer;
