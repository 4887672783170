import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FaArrowAltCircleLeft, FaPlus } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { IoIosMore } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector } from "../../store/store";
import { Action, AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import {
  setAllProspectsList,
  setListId,
  setLists,
} from "../../store/slices/ownerSlice";
import { toast } from "react-toastify";
import {
  createList,
  deleteList,
  updateList,
} from "../../store/features/ownerActions";
import { fetchLists, fetchProspects } from "../../utils";
import { FaAngleLeft, FaAngleRight, FaPencil } from "react-icons/fa6";
import { MdClose, MdDelete, MdMenu } from "react-icons/md";
import { Popover } from "antd";
import {
  setListDescription,
  setListName,
  setProspects,
} from "../../store/slices/prospectsSlice";
import { useNavigate } from "react-router-dom";
import { useWindowResize } from "../../hooks/useWindowResize";
import { List } from "../../types";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";

// Assuming Props type and lists array are defined above

type Props = {};

const Lists = (props: Props) => {
  const {
    loading,
    owner,
    lists,
    listId: selectedListId,
    currentWorkspace,
    allProspectsList,
  } = useSelector(ownerSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const [fetchOptions, setFetchOptions] = useState({
    limit: 10,
    page: 1,
  });
  // const [hoveredItemId, setHoveredItemId] = useState<string>("");
  const [updateId, setUpdateId] = useState<string>("");

  const [deleteId, setDeleteId] = useState<string>("");
  const [newList, setNewList] = useState({
    name: "",
    description: "",
  });
  const { width } = useWindowResize();
  const [addList, setAddList] = useState<boolean>(false);
  const [editList, setEditList] = useState<boolean>(false);
  const [editingList, setEditingList] = useState<{
    listName: string;
    description: string;
  }>({
    listName: "",
    description: "",
  });
  const [updatingState, setUpdatingState] = useState<boolean>(false);
  const [showMoreOptions, setShowMoreOptions] = useState<string>("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showListBar, setShowListBar] = useState(true);
  const navigate = useNavigate();

  const optionsModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Fetch lists
    (async () => {
      try {
        const res = await fetchLists(
          owner?._id as string,
          currentWorkspace?._id as string
        );
        if (res) {
          dispatch(setLists(res));
          const allList = res.find(
            (list: List) => list.listName === "All Prospects"
          );
          if (allList) dispatch(setAllProspectsList(allList));

          dispatch(setListId(res[0]?._id));
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching lists");
      }
    })();
  }, [addList, updatingState, navigate]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        optionsModalRef.current &&
        !optionsModalRef.current.contains(e.target)
      ) {
        setShowMoreOptions("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsModalRef]);

  const addNewList = async () => {
    try {
      if (newList.name === "" || newList.name.trim() === "") {
        toast.error("List name is required");
        return;
      }
      const res = await dispatch(
        createList({
          ownerId: owner?._id as string,
          listName: newList.name,
          description: newList.description,
          workspaceId: currentWorkspace?._id as string,
        })
      );

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("List created successfully");
        setAddList(false);
        setNewList({
          name: "",
          description: "",
        });
      } else {
        // console.log(res);
        toast.error("List name already exists");
      }
    } catch (error) {
      toast.error("Error creating list");
      console.log(error);
    }
  };

  const handleUpdateList = async () => {
    try {
      setUpdatingState(false);
      if (editingList.listName === "" || editingList.listName.trim() === "") {
        toast.error("List name is required");
        return;
      }
      const res = await dispatch(
        updateList({
          listId: updateId,
          listName: editingList.listName,
          description: editingList.description,
        })
      );

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("List updated successfully");
        setEditList(false);
        setEditingList({
          listName: "",
          description: "",
        });
        setUpdatingState(true);
      } else {
        // console.log(res);
        toast.error("List name already exists");
      }
    } catch (error) {
      toast.error("Error updating list");
      console.log(error);
    }
  };

  const handleDeleteList = async () => {
    try {
      setUpdatingState(false);
      console.log(deleteId);
      const res = await dispatch(deleteList({ listId: deleteId }));

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("List deleted successfully");
        setShowDeleteConfirmation(false);
        // dispatch(setListId(lists[0]._id));
        setUpdatingState(true);
      } else {
        toast.error("Error deleting list");
      }
    } catch (error: any) {
      toast.error("Error deleting list");
      setShowDeleteConfirmation(false);
      console.log(error);
    }
  };

  const handleSearchWithDebounce = useCallback(
    _.debounce(async (e) => {
      try {
        const res = await axios.post("/prospect/searchList", {
          ownerId: owner?._id,
          searchQuery: e.target.value,
        });

        const lists = res.data;
        dispatch(setLists(lists));
        if (lists.length > 0) {
          dispatch(setListId(lists[0]._id));
        } else {
          toast.error("No lists found");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error searching lists");
      }
    }, 1000),
    [owner, dispatch]
  );

  const handleProspectsFetch = async (listId: string) => {
    if (listId === selectedListId) return;
    let loader = toast.loading("Fetching prospects...");
    try {
      if (!owner?._id || !listId) return;
      const prospects = await fetchProspects(
        owner._id,
        listId,
        fetchOptions.page,
        fetchOptions.limit
      );
      if (prospects) {
        // console.log(prospects);

        dispatch(setProspects(prospects.prospects));
        dispatch(setListId(prospects._id));
        dispatch(setListName(prospects.listName));
        dispatch(setListDescription(prospects.description));

        toast.dismiss(loader);
        if (prospects.prospects.length === 0) {
          toast.warning("No prospects in this list");
        } else {
          toast.success("Prospects fetched successfully");
        }
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("An error occurred");
      console.log(error);
    }
  };
  return (
    <div
      className={`${showListBar ? "" : "w-auto"}  px-4 mr-2 border-r-2 ${
        width < 1920 ? "text-xs" : "text-sm"
      }  border-mainColor/20 flex flex-col gap-4 mb-4  h-full relative`}
    >
      <div
        onClick={() => {
          setShowListBar(!showListBar);
          setAddList(false);
        }}
        className="text-mainColor absolute -right-4 border-2 border-mainColor cursor-pointer z-50 flex p-1 bg-white rounded-full"
      >
        {showListBar ? <FaAngleLeft size={15} /> : <FaAngleRight size={15} />}
      </div>
      {showListBar ? (
        <div className="flex justify-between items-center">
          {showListBar && <h1 className="text-xl font-semibold">Lists</h1>}
          <button
            onClick={() => setAddList(!addList)}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-xs rounded-md"
          >
            {addList ? (
              "Cancel"
            ) : (
              <>
                <FaPlus className="text-white" size={15} />
                <p>Add List</p>
              </>
            )}
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <button
            onClick={() => {
              setShowListBar(!showListBar);
              setAddList(!addList);
            }}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-sm rounded-md"
          >
            <FaPlus className="text-white text-center mx-auto" size={20} />
          </button>
        </div>
      )}
      {showListBar ? (
        <div className="border-2 border-mainColor flex gap-2 p-1 rounded-md items-center bg-white">
          <CiSearch size={20} />
          <input
            onChange={(e) => {
              handleSearchWithDebounce(e);
            }}
            type="text"
            placeholder="Search"
            className="outline-none w-full"
          />
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <button
            onClick={() => {
              setShowListBar(!showListBar);
            }}
            className="bg-white shadow-lg flex items-center justify-center gap-2 text-mainColor p-2 text-sm rounded-md"
          >
            <CiSearch className="" size={20} />
          </button>
        </div>
      )}
      <div
        className={`flex ${
          showListBar ? "w-full" : "w-auto"
        }  flex-col gap-2 noScroll mb-4 overflow-y-scroll`}
      >
        {addList && (
          <div className="flex flex-col gap-2">
            <input
              onChange={(e) => setNewList({ ...newList, name: e.target.value })}
              type="text"
              placeholder="List Name"
              className="p-2 border-2 outline-mainColor border-mainColor/20 rounded-md"
            />
            <textarea
              onChange={(e) =>
                setNewList({ ...newList, description: e.target.value })
              }
              placeholder="Description"
              className="p-2 border-2 outline-mainColor border-mainColor/20 rounded-md resize-none"
            ></textarea>

            <button
              onClick={addNewList}
              className="bg-mainColor font-semibold text-white p-2 rounded-md"
            >
              Add List
            </button>
          </div>
        )}
        {editList && (
          <div className="flex flex-col gap-2">
            <input
              value={editingList.listName}
              onChange={(e) =>
                setEditingList({ ...editingList, listName: e.target.value })
              }
              type="text"
              placeholder="List Name"
              className="p-2 border-2 outline-mainColor border-mainColor/20 rounded-md"
            />
            <textarea
              value={editingList.description}
              onChange={(e) =>
                setEditingList({ ...editingList, description: e.target.value })
              }
              placeholder="Description"
              className="p-2 border-2 outline-mainColor border-mainColor/20 rounded-md resize-none"
            ></textarea>

            <div className="flex items-center justify-center gap-2">
              <button
                onClick={handleUpdateList}
                className="bg-mainColor font-semibold text-white p-2 rounded-md"
              >
                Update
              </button>
              <button
                onClick={() => {
                  setEditList(false);
                  setEditingList({
                    listName: "",
                    description: "",
                  });
                }}
                className="text-mainColor font-semibold  p-2 rounded-md border border-mainColor"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {allProspectsList && (
          <div
            // onMouseEnter={() => setHoveredItemId(list._id)}
            // onMouseLeave={() => setHoveredItemId("")}
            key={allProspectsList._id}
            className="bg-mainColor/40 flex justify-center items-center gap-2 border-primary border-2 p-2 rounded-lg cursor-pointer relative"
          >
            <Popover content={allProspectsList.listName}>
              <span
                onClick={() => handleProspectsFetch(allProspectsList._id)}
                className="p-2 px-3 rounded-lg  bg-mainColor text-white"
              >
                {allProspectsList.listName[0].toLocaleUpperCase()}
              </span>
            </Popover>
            {showListBar && (
              <h1
                onClick={() => {
                  handleProspectsFetch(allProspectsList._id);
                }}
                className="text-md whitespace-nowrap font-semibold w-full"
              >
                {allProspectsList.listName.length > 20 ? (
                  <Popover content={allProspectsList.listName}>
                    {allProspectsList.listName.slice(0, 15)}...
                  </Popover>
                ) : (
                  allProspectsList.listName
                )}
              </h1>
            )}
            {showListBar && (
              <p className="py-1 text-xs px-2 bg-mainColor text-white rounded-lg">
                {allProspectsList.prospects.length}
              </p>
            )}
          </div>
        )}
        <div className="p-[0.3px] rounded-lg w-full bg-gray-400"></div>
        {lists.length > 0 ? (
          lists.map(
            (list: any) =>
              list.listName !== "All Prospects" && (
                <div
                  key={list._id}
                  className="bg-mainColor/20 flex justify-center items-center gap-2 border-primary border-2 p-2 rounded-lg cursor-pointer relative"
                >
                  <Popover content={list.listName}>
                    <span
                      onClick={() => handleProspectsFetch(list._id)}
                      className="p-2 px-3 rounded-lg  bg-mainColor text-white"
                    >
                      {list.listName[0].toLocaleUpperCase()}
                    </span>
                  </Popover>
                  {showListBar && (
                    <h1
                      onClick={() => {
                        handleProspectsFetch(list._id);
                      }}
                      className="text-md whitespace-nowrap font-semibold w-full"
                    >
                      {list.listName.length > 20 ? (
                        <Popover content={list.listName}>
                          {list.listName.slice(0, 15)}...
                        </Popover>
                      ) : (
                        list.listName
                      )}
                    </h1>
                  )}

                  {showListBar && (
                    <div className="flex items-center">
                      <span>
                        <p className="py-1 text-xs px-2 bg-white text-black rounded-lg">
                          {list.prospects.length}
                        </p>
                      </span>
                      <span
                        className="relative"
                        onClick={() => {
                          if (showMoreOptions === list._id) {
                            setShowMoreOptions("");
                            return;
                          }
                          setShowMoreOptions(list._id);
                        }}
                      >
                        <BsThreeDotsVertical
                          size={20}
                          className="text-gray-600 cursor-pointer"
                        />
                        {showMoreOptions === list?._id && (
                          <div
                            ref={optionsModalRef}
                            className="absolute bg-white p-2 rounded-lg w-[80px] z-50 right-0 flex flex-col gap-2"
                          >
                            <span
                              onClick={() => {
                                setUpdateId(list._id);
                                setEditList(true);
                                setEditingList({
                                  listName: list.listName,
                                  description: list.description || "",
                                });
                              }}
                              className="hover:bg-blue-400/20 rounded-lg p-1 text-xs gap-2 items-center justify-start flex"
                            >
                              <FaPencil size={12} />
                              <span>Edit</span>
                            </span>
                            <span
                              onClick={() => {
                                // setHoveredItemId(list._id);
                                setDeleteId(list._id);
                                setShowDeleteConfirmation(true);
                              }}
                              className="hover:bg-red-400/20 rounded-lg text-xs flex justify-start items-center"
                            >
                              <span className="p-1">
                                <MdDelete className="text-red-500" size={15} />
                              </span>
                              <span>Delete</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              )
          )
        ) : (
          <div className="text-center text-gray-400">No lists found</div>
        )}
      </div>

      {showDeleteConfirmation && (
        <div>
          <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 z-50"></div>
          <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="bg-white w-[30%] h-[20%] flex flex-col justify-evenly items-center p-4 rounded-lg relative">
              <span
                onClick={() => {
                  setShowDeleteConfirmation(false);
                }}
              >
                <MdClose className="absolute top-2 right-2 text-xl cursor-pointer" />
              </span>
              <h1 className="text-2xl font-semibold">Delete List</h1>
              <p>Are you sure you want to delete this list?</p>
              <div className="flex gap-4">
                <button
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                  className="bg-mainColor text-white p-2 rounded-md"
                >
                  Cancel
                </button>
                <button
                  disabled={loading}
                  onClick={() => {
                    handleDeleteList();
                  }}
                  className="bg-red-500 text-white p-2 rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lists;
