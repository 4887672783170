import { Flex } from "antd";
import React from "react";
import Lists from "../components/propects/Lists";
import ProspectMain from "../components/propects/ProspectMain";

type Props = {utils: any, setShowExpiredAlert: any};

const Prospects = (props: Props) => {
  return (
    <div className="flex h-full pb-10">
      <Lists />
      <div className="p-4 w-full h-full pb-8">
        <ProspectMain utils={props.utils} setShowExpiredAlert={props.setShowExpiredAlert}/>
      </div>
    </div>
  );
};

export default Prospects;
